import {axiosi} from '../../config/axios'


export const createOrder=async(order)=>{
    try {
       
        const res=await axiosi.post("https://orginv8.in:8443/orders",order)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const initiatePayment=async(paylod)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/orders/initiatePayment",paylod)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const getOrderByUserId=async(id)=>{
    try {
        const res=await axiosi.get(`https://orginv8.in:8443/orders/user/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const getAllOrders=async()=>{
    try {
        const res=await axiosi.get(`https://orginv8.in:8443/orders`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const updateOrderById=async(update)=>{
    try {
        const res=await axiosi.patch(`https://orginv8.in:8443/orders/${update._id}`,update)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}