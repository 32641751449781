import {axiosi} from '../../config/axios'

export const signup=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/signup",cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const login=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/login",cred)
        return res.data
    } catch (error) {
        throw error.response.data   
    }
}

export const verifyOtp=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/verify-otp",cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const resendOtp=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/resend-otp",cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const forgotPassword=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/forgot-password",cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const resetPassword=async(cred)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/auth/reset-password",cred)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const checkAuth=async(cred)=>{
    try {
        let ip=process.env.REACT_APP_BASE_URL;
        const res=await axiosi.get("https://orginv8.in:8443/auth/check-auth")
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const logout=async()=>{
    try {
        const res=await axiosi.get("https://orginv8.in:8443/auth/logout")
        return res.data
    } catch (error) {
        throw error.response.data
    }
}