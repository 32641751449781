import {axiosi} from '../../config/axios'

export const createReview=async(review)=>{
    try {
        const res=await axiosi.post('https://orginv8.in:8443/reviews',review)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const fetchReviewsByProductId=async(id)=>{
    try {
        const res=await axiosi.get(`https://orginv8.in:8443/reviews/product/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

export const updateReviewById=async(update)=>{
    try {
        const res=await axiosi.patch(`https://orginv8.in:8443/reviews/${update._id}`,update)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const deleteReviewById=async(id)=>{
    try {
        const res=await axiosi.delete(`https://orginv8.in:8443/reviews/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}