import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import { QRCodePng, appStorePng, googlePlayPng, facebookPng, instagramPng, twitterPng, linkedinPng } from '../../assets'
import SendIcon from '@mui/icons-material/Send';
import { MotionConfig, motion } from 'framer-motion';
import { Link } from 'react-router-dom';



export const Footer = () => {

    const theme = useTheme()
    const is700 = useMediaQuery(theme.breakpoints.down(700))

    const labelStyles = {
        fontWeight: 300,
        cursor: 'pointer'
    }

    return (
        <Stack sx={{ backgroundColor: theme.palette.primary.main, paddingTop: "3rem", paddingLeft: is700 ? "1rem" : "3rem", paddingRight: is700 ? "1rem" : "3rem", paddingBottom: "1.5rem", rowGap: "5rem", color: theme.palette.primary.light, justifyContent: "space-around" }}>

            {/* upper */}
            <Stack flexDirection={'row'} rowGap={'1rem'} justifyContent={is700 ? "" : 'space-around'} flexWrap={'wrap'}>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6' fontSize={'1.5rem'}>Exclusive</Typography>
                    <Typography variant='h6'>Subscribe</Typography>
                    <Typography sx={labelStyles}>Get 10% off your first order</Typography>
                    <TextField placeholder='Enter your email' sx={{ border: '1px solid white', borderRadius: "6px" }} InputProps={{ endAdornment: <IconButton><SendIcon sx={{ color: theme.palette.primary.light }} /></IconButton>, style: { color: "whitesmoke" } }} />
                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Support</Typography>
                    <Typography sx={labelStyles}>490 , udyog vihar phase 5, gurgoan, haryana - 122016.</Typography>
                    <Typography sx={labelStyles}>engineering@orginv8.com</Typography>
                    <Typography sx={labelStyles}>+918595576797</Typography>
                    <Typography variant='h6'>Registered Address</Typography>
                    <Typography sx={labelStyles}>B-19,2nd Floor,Shiv Park,School Road,Khanpur,New Delhi - 110062</Typography>
                    
                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Account</Typography>
                    <Typography sx={labelStyles}>My Account</Typography>
                    <Typography sx={labelStyles}>Login / Register</Typography>
                    <Typography sx={labelStyles}>Cart</Typography>
                    <Typography sx={labelStyles}>Wishlist</Typography>
                    <Typography sx={labelStyles}>Shop</Typography>
                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Quick Links</Typography>
                    <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography sx={labelStyles}>Privacy Policy</Typography>
                    </Link>
                    <Link to="/refund-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography sx={labelStyles}>Refund Policy</Typography>
                    </Link>
                    <Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography sx={labelStyles}>About Us</Typography>
                    </Link>
                    <Link to="/shipping-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography sx={labelStyles}>Shipping Policy</Typography>
                    </Link>

                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Download App</Typography>
                    <Typography sx={{ ...labelStyles, color: "graytext", fontWeight: 500 }}>Save $3 with App New User Only</Typography>
                    <Stack flexDirection={'row'} columnGap={'.5rem'}>

                        <Box width={'100px'} height={"100px"}>
                            <img src={QRCodePng} height={'100%'} width={'100%'} style={{ objectFit: 'contain' }} alt="QR Code" />
                        </Box>

                        <Stack justifyContent={'space-around'}>
                            <Stack>
                                <img style={{ width: "100%", height: "100%", cursor: "pointer" }} src={googlePlayPng} alt="GooglePlay" />
                            </Stack>
                            <Stack>
                                <img style={{ width: "100%", height: '100%', cursor: "pointer" }} src={appStorePng} alt="AppStore" />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack mt={.6} flexDirection={'row'} columnGap={'2rem'}>
                        <MotionConfig whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
                            <motion.a href="https://www.facebook.com/profile.php?id=61566401565182" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={facebookPng} alt="Facebook" />
                            </motion.a>
                            <motion.a href="https://x.com/LlpOriginal" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={twitterPng} height={15} width={15} alt="Twitter" />
                            </motion.a>
                            <motion.a href="https://www.instagram.com/original_innovation_llp" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={instagramPng} alt="Instagram" />
                            </motion.a>
                            <motion.a href="https://www.linkedin.com/in/original-innovation-llp-794968313/" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={linkedinPng} alt="Linkedin" />
                            </motion.a>
                        </MotionConfig>
                    </Stack>
                </Stack>
            </Stack>
            {/* lower */}
            <Stack alignSelf={"center"}>
                <Typography color={'GrayText'}>&copy; Original Innovation LLP {new Date().getFullYear()}. All right reserved</Typography>
            </Stack>

        </Stack>
    )
}
