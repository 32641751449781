import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createOrderAsync } from '../features/order/OrderSlice';

const OrderSuccess = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const order = location.state ? location.state.order : null;
 
    const getQueryParams = (url) => {
        return new URLSearchParams(url.search);
    };

    const queryParams = getQueryParams(location);
    const paymentResponse = queryParams.get('paymentResponse');

    // Parse the paymentResponse if it exists
    const parsedPaymentResponse = paymentResponse ? JSON.parse(decodeURIComponent(paymentResponse)) : null;

    // const orderFromLocalStorage = JSON.parse(localStorage.getItem('orderData'));
    // const order = location.state ? location.state.order : orderFromLocalStorage;

    useEffect(() => {
        const newOrder = {
            user: order.user,
            items: order.item,
            address: order.address,
            paymentMode: order.paymentMode,
            total: order.total,
            email: order.email,
            guestFlag: order.guestFlag,
            paymentResponse: parsedPaymentResponse,
        };
        dispatch(createOrderAsync(newOrder))
    }, [dispatch, order])


    return (
        <div>
            <h1>Order success</h1>
        </div>
    )
}

export default OrderSuccess