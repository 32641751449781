import { Stack, TextField, Typography, Button, Menu, MenuItem, Select, Grid, FormControl, Radio, Paper, IconButton, Box, useTheme, useMediaQuery } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { Cart } from '../../cart/components/Cart'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addAddressAsync, fetchAddressByUserIdAsync, selectAddressStatus, selectAddresses } from '../../address/AddressSlice'
import { selectLoggedInUser } from '../../auth/AuthSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { createOrderAsync, initialpaymentAsync, selectCurrentOrder, selectOrderStatus } from '../../order/OrderSlice'
import { resetCartByUserIdAsync, selectCartItems } from '../../cart/CartSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SHIPPING, TAXES } from '../../../constants'
import { motion } from 'framer-motion'


export const Checkout = () => {  
    const status = ''
    const addresses = useSelector(selectAddresses)
    const [selectedAddress, setSelectedAddress] = useState(addresses[0])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cash')
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm()
    const dispatch = useDispatch()
    const loggedInUser = useSelector(selectLoggedInUser)
    const addressStatus = useSelector(selectAddressStatus)
    const navigate = useNavigate()
    const cartItems = useSelector(selectCartItems)
    const orderStatus = useSelector(selectOrderStatus)
    const currentOrder = useSelector(selectCurrentOrder)
    const orderTotal = cartItems.reduce((acc, item) => (item.product.price * item.quantity) + acc, 0)
    const theme = useTheme()
    const is900 = useMediaQuery(theme.breakpoints.down(900))
    const is480 = useMediaQuery(theme.breakpoints.down(480))
    const [guestUserAddress, setGuestAddress] = useState(null)


    useEffect(() => {
        fetchAddressByUserIdAsync()
    }, [])
    useEffect(() => {
        if (addressStatus === 'fulfilled') {
            reset()
        }
        else if (addressStatus === 'rejected') {
            alert('Error adding your address')
        }
    }, [addressStatus])

    useEffect(() => {
        if (currentOrder && currentOrder?._id) {
            dispatch(resetCartByUserIdAsync(loggedInUser?._id))
            navigate(`/order-success/${currentOrder?._id}`)
        }
    }, [currentOrder])

    const handleAddAddress = (data) => {
        if (loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID) {
            const guestAddress = { ...data, user: loggedInUser._id };
            setGuestAddress(guestAddress);
        } else {
            const address = { ...data, user: loggedInUser._id };
            dispatch(addAddressAsync(address));
        }
    };

    const handleCreateOrder = () => {
        const order = {
            user: loggedInUser._id, item: cartItems,
            address: selectedAddress,
            paymentMode: selectedPaymentMethod,
            total: orderTotal + SHIPPING + TAXES,
            email: loggedInUser.email,
            guestFlag: loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID,
            item: cartItems.map(item => ({
                productId: item.product._id,
                quantity: item.quantity,
            })),
            
        }
        const address = {
            city: order.address.city,
            state: order.address.state,
            street: order.address.street,
            postalCode: order.address.postalCode,
            phoneNumber: order.address.phoneNumber
        }



        if (loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID) {

            if (selectedPaymentMethod === 'CARD') {
                const payload = {
                    address: address,
                    product: order.item,
                    returnUrl: "https://orginv8.in/order-success",
                    userId: order.user,
                    guestFlag: order.guestFlag
                }
                navigate('/payment_page', { state: { payload,order } });

            } else {
                const orderCash = {
                    user: loggedInUser._id, item: cartItems,
                    address: selectedAddress,
                    paymentMode: selectedPaymentMethod,
                    total: orderTotal + SHIPPING + TAXES,
                    email: loggedInUser.email,
                    guestFlag: false
                }
                dispatch(createOrderAsync(orderCash,))
            }

        }
        else {
            if (selectedPaymentMethod === 'CARD') {
                const payload = {
                    address: address,
                    products: order.item,
                    returnUrl: "https://orginv8.in/order-success",
                    userId: order.user,
                    guestFlag: order.guestFlag
                }
            
               navigate('/payment_page', { state: { payload,order } });
           
            } else {
                const orderCash = {
                    user: loggedInUser._id, item: cartItems,
                    address: selectedAddress,
                    paymentMode: selectedPaymentMethod,
                    total: orderTotal + SHIPPING + TAXES,
                    email: loggedInUser.email,
                    guestFlag: false
                }
                dispatch(createOrderAsync(orderCash))
            }
        }
    }

    return (
        <Stack flexDirection={'row'} p={2} rowGap={10} justifyContent={'center'} flexWrap={'wrap'} mb={'5rem'} mt={2} columnGap={4} alignItems={'flex-start'}>

            {/* left box */}
            <Stack rowGap={4}>

                {/* heading */}
                <Stack flexDirection={'row'} columnGap={is480 ? 0.3 : 1} alignItems={'center'}>
                    <motion.div whileHover={{ x: -5 }}>
                        <IconButton component={Link} to={"/cart"}><ArrowBackIcon fontSize={is480 ? "medium" : 'large'} /></IconButton>
                    </motion.div>
                    <Typography variant='h4'>Shipping Information</Typography>
                </Stack>

                {/* address form */}
                <Stack component={'form'} noValidate rowGap={2} onSubmit={handleSubmit(handleAddAddress)}>
                    <Stack>
                        <Typography gutterBottom>Name</Typography>
                        <TextField placeholder='Eg. Home, Buisness' {...register("type", { required: true })} />
                    </Stack>


                    <Stack>
                        <Typography gutterBottom>Street</Typography>
                        <TextField {...register("street", { required: true })} />
                    </Stack>

                    <Stack>
                        <Typography gutterBottom>Country</Typography>
                        <TextField {...register("country", { required: true })} />
                    </Stack>

                    <Stack>
                        <Typography gutterBottom>Phone Number</Typography>
                        <TextField type='number' {...register("phoneNumber", { required: true })} />
                    </Stack>
                    {loggedInUser._id == process.env.REACT_APP_GUESTUSER_ID && (
                        <Stack>
                            <Typography gutterBottom>Email</Typography>
                            <TextField type="text" {...register("email", { required: true })} />
                        </Stack>
                    )}

                    <Stack flexDirection={'row'}>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>City</Typography>
                            <TextField  {...register("city", { required: true })} />
                        </Stack>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>State</Typography>
                            <TextField  {...register("state", { required: true })} />
                        </Stack>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>Postal Code</Typography>
                            <TextField type='number' {...register("postalCode", { required: true })} />
                        </Stack>
                    </Stack>

                    <Stack flexDirection={'row'}>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>GST Number(Optional)</Typography>
                            <TextField type='text'  {...register("gstNumber", { required: false })} />
                        </Stack>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>Company Name(Optional)</Typography>
                            <TextField type='text' {...register("gstCompanyName", { required: false })} />
                        </Stack>
                        <Stack width={'100%'}>
                            <Typography gutterBottom>Business Address(Optional)</Typography>
                            <TextField type='text' {...register("gstAddress", { required: false })} />
                        </Stack>
                    </Stack>
                    <Stack flexDirection={'row'} alignSelf={'flex-end'} columnGap={1}>
                        <LoadingButton loading={status === 'pending'} type='submit' variant='contained'>add</LoadingButton>
                        <Button color='error' variant='outlined' onClick={() => reset()}>Reset</Button>
                    </Stack>
                </Stack>

                {/* existing address */}
                <Stack direction="row" spacing={4} justifyContent="space-between">
                    {/* Shipping Address */}
                    <Stack rowGap={3} width="50%">
                        <Stack>
                            <Typography variant="h6"> Shipping Address</Typography>
                            <Typography variant="body2" color="text.secondary">Choose from existing Addresses</Typography>
                        </Stack>
                        <Grid container gap={2} justifyContent="flex-start" alignContent="flex-start">
                            {(loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID && guestUserAddress) ? (
                                <FormControl item>
                                    <Stack p={2} width="20rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                        <Stack flexDirection="row" alignItems="center">
                                            <Radio checked={selectedAddress === guestUserAddress}
                                                name="shppingAddressRadioGroup"
                                                onChange={() => setSelectedAddress(guestUserAddress)}
                                            />
                                            <Typography>{guestUserAddress.type}</Typography>
                                        </Stack>
                                        {/* Shipping address details */}
                                        <Stack>
                                            <Typography>{guestUserAddress.street}</Typography>
                                            <Typography>{guestUserAddress.state}, {guestUserAddress.city}, {guestUserAddress.country}, {guestUserAddress.postalCode}</Typography>
                                            <Typography>{guestUserAddress.phoneNumber}</Typography>
                                        </Stack>
                                    </Stack>
                                </FormControl>
                            ) : (
                                addresses.map((address) => (
                                    <FormControl item key={address._id}>
                                        <Stack p={2} width="20rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                            <Stack flexDirection="row" alignItems="center">
                                                <Radio checked={selectedAddress === address} name="billingAddressRadioGroup" onChange={() => setSelectedAddress(address)} />
                                                <Typography>{address.type}</Typography>
                                            </Stack>
                                            {/* Shipping address details */}
                                            <Stack>
                                                <Typography>{address.street}</Typography>
                                                <Typography>{address.state}, {address.city}, {address.country}, {address.postalCode}</Typography>
                                                <Typography>{address.phoneNumber}</Typography>
                                            </Stack>
                                        </Stack>
                                    </FormControl>
                                ))
                            )}
                        </Grid>
                    </Stack>
                    {/* Billing Address Section */}
                    {
                        (guestUserAddress?.gstNumber || addresses?.some(address => address.gstNumber)) ? (
                            <Stack rowGap={3} width="50%">
                                <Stack>
                                    <Typography variant="h6">Billing Address</Typography>
                                    <Typography variant="body2" color="text.secondary">Choose from existing Addresses</Typography>
                                </Stack>
                                <Grid container gap={2} justifyContent="flex-start" alignContent="flex-start">
                                    {(loggedInUser._id === process.env.REACT_APP_GUESTUSER_ID && guestUserAddress) ? (
                                        <FormControl item>
                                            <Stack p={2} width="26rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                                <Grid container spacing={2}>
                                                    {/* Address Block */}
                                                    <Grid item xs={6}>
                                                        <Stack flexDirection="row" alignItems="center">
                                                            <Radio
                                                                checked={selectedAddress === guestUserAddress}
                                                                name="addressRadioGroup"
                                                                onChange={() => setSelectedAddress(guestUserAddress)}
                                                            />
                                                            <Typography>{guestUserAddress.type}</Typography>
                                                        </Stack>
                                                        {/* Address details */}
                                                        <Stack>
                                                            <Typography>{guestUserAddress.street}</Typography>
                                                            <Typography>
                                                                {guestUserAddress.state}, {guestUserAddress.city}, {guestUserAddress.country}, {guestUserAddress.postalCode}
                                                            </Typography>
                                                            <Typography>{guestUserAddress.phoneNumber}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    {/* GST Block */}
                                                    <Grid item xs={6} textAlign="left" marginTop={1}>
                                                        <Stack flexDirection="row" alignItems="center">
                                                            <Typography sx={{ fontSize: '1rem' }}>GST Details:</Typography>

                                                        </Stack>
                                                        <Stack>
                                                            <Typography>{guestUserAddress.gstNumber}</Typography>
                                                            <Typography>{guestUserAddress.gstCompanyName}</Typography>
                                                            <Typography>{guestUserAddress.gstAddress}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </FormControl>
                                    ) : (
                                        addresses.map((address) => (
                                            <FormControl item key={address._id}>
                                                <Stack p={2} width="26rem" height="15rem" rowGap={2} component={Paper} elevation={1}>
                                                    <Grid container spacing={2}>
                                                        {/* Address Block */}
                                                        <Grid item xs={6}>
                                                            <Stack flexDirection="row" alignItems="center">
                                                                <Radio
                                                                    checked={selectedAddress === guestUserAddress}
                                                                    name="addressRadioGroup"
                                                                    onChange={() => setSelectedAddress(guestUserAddress)}
                                                                />
                                                                <Typography>{address.type}</Typography>
                                                            </Stack>
                                                            {/* Address details */}
                                                            <Stack>
                                                                <Typography>{address.street}</Typography>
                                                                <Typography>
                                                                    {address.state}, {address.city}, {address.country}, {address.postalCode}
                                                                </Typography>
                                                                <Typography>{address.phoneNumber}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        {/* GST Block */}
                                                        <Grid item xs={6} textAlign="left" marginTop={1}>
                                                            <Stack flexDirection="row" alignItems="center">
                                                                <Typography sx={{ fontSize: '1rem' }}>GST Details:</Typography>

                                                            </Stack>
                                                            <Stack>
                                                                <Typography>{address.gstNumber}</Typography>
                                                                <Typography>{address.gstCompanyName}</Typography>
                                                                <Typography>{address.gstAddress}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>

                                                </Stack>
                                            </FormControl>
                                        ))
                                    )}
                                </Grid>
                            </Stack>
                        ) : null
                    }

                </Stack>

                {/* payment methods */}
                <Stack rowGap={3}>

                    <Stack>
                        <Typography variant='h6'>Payment Methods</Typography>
                        <Typography variant='body2' color={'text.secondary'}>Please select a payment method</Typography>
                    </Stack>

                    <Stack rowGap={2}>

                        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                            <Radio value={selectedPaymentMethod} name='paymentMethod' checked={selectedPaymentMethod === 'COD'} onChange={() => setSelectedPaymentMethod('COD')} />
                            <Typography>Cash</Typography>
                        </Stack>

                        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                            <Radio value={selectedPaymentMethod} name='paymentMethod' checked={selectedPaymentMethod === 'CARD'} onChange={() => setSelectedPaymentMethod('CARD')} />
                            <Typography>Card</Typography>
                        </Stack>

                    </Stack>


                </Stack>
            </Stack>

            {/* right box */}
            <Stack width={is900 ? '100%' : 'auto'} alignItems={is900 ? 'flex-start' : ''}>
                <Typography variant='h4'>Order summary</Typography>
                <Cart checkout={true} />
                <LoadingButton fullWidth loading={orderStatus === 'pending'} variant='contained' onClick={handleCreateOrder} size='large'>Pay and order</LoadingButton>
            </Stack>

        </Stack>
    )
}