import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Box>
                <Typography variant="h4" gutterBottom>
                    About Us
                </Typography>
               
                <Typography variant="body1" paragraph>
                    We are dedicated to providing the best quality products and customer service. Our company was founded with a vision to offer premium products at affordable prices while ensuring customer satisfaction at every step. We work closely with trusted partners and suppliers to bring you the latest and most innovative products. Your satisfaction is our priority, and we strive to exceed your expectations with every purchase. For more details about our story, values, and mission, please visit the About Us section on our website.
               </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
