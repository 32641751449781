// features/subadmin/subadminApi.js
import { axiosi } from '../../config/axios'; // Adjust the import as needed

// Function for subadmin login
export const loginSubAdmin = async (credentials) => {
    try {
        const res = await axiosi.post('https://orginv8.in:8443/subadmin/login', credentials);
        localStorage.setItem('subadminToken', res.data.token); // Store token in localStorage
        return res.data; // Return data for further use
    } catch (error) {
        const errorMessage = error.response ? error.response.data : 'Network Error';
        throw errorMessage; // Return the error message
    }
};

// Function for fetching devices



// Other functions like fetchSubAdminDetails, updateSubAdminDetails, and deleteSubAdminAccount remain unchanged
