import { axiosi } from "../../config/axios";

export const addAddress=async(address)=>{
    try {
        const res=await axiosi.post("https://orginv8.in:8443/address",address)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const fetchAddressByUserId=async(id)=>{
    try {
        const res=await axiosi.get(`https://orginv8.in:8443/address/user/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const updateAddressById=async(update)=>{
    try {
        const res=await axiosi.patch(`https://orginv8.in:8443/address/${update._id}`,update)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const deleteAddressById=async(id)=>{
    try {
        const res=await axiosi.delete(`https://orginv8.in:8443/address/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}