import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../AuthSlice";
import { Navigate } from "react-router-dom"; // Ensure you're using the correct import

export const Protected = ({ children }) => {
    const loggedInUser = useSelector(selectLoggedInUser);
    // Check if the user is logged in and verified, or is an admin/sub-admin
    if (loggedInUser && (loggedInUser.isVerified || loggedInUser.isAdmin || loggedInUser.isSubAdmin)) {
        return children; // Render protected children
    }
//return children;
    // Redirect to the login page if the user is not authenticated or not allowed access
    return <Navigate to="/login" replace />;
};
