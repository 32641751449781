// features/subadmin/deviceApi.js
import { axiosi } from '../../config/axios'; // Adjust the import as needed

export const fetchDevicesBySubAdminId = async (subAdminId) => {
    try {
        const res = await axiosi.post(`https://orginv8.in:8443/device/fetchDevicesBySubAdminId`, {
           "subAdminId": subAdminId  // Using params to send the subAdminId
        });// Adjust the URL
        return res.data.devices; // Adjust based on your API response
    } catch (error) {
        throw error.response.data; // Handle error
    }
};
