import React, { useEffect, useState } from 'react';
import { fetchDevicesBySubAdminId } from '../features/subadmin/deviceApi'; // Ensure the correct path
import { useNavigate } from 'react-router-dom'; // Import useNavigate for logout
import './Dashboard.css'; // Assuming you're adding CSS for the dashboard

const SubAdminDashboard = () => {
    const [devices, setDevices] = useState([]); // Local state for devices
    const [isLoading, setIsLoading] = useState(true); // Local state for loading
    const [error, setError] = useState(null); // Local state for error
    const [selectedTab, setSelectedTab] = useState('devices'); // State for managing tab selection

    const navigate = useNavigate(); // Use navigate for redirection after logout
    const subAdminId = localStorage.getItem('subadminId'); // Get subAdminId from local storage

    useEffect(() => {
        const getDevices = async () => {
            if (subAdminId && selectedTab === 'devices') {
                try {
                    console.log('Fetching devices for:', subAdminId);
                    const response = await fetchDevicesBySubAdminId(subAdminId); // Call the API
                    console.log(response);

                    // Check for successful response and set devices
                    if (response) {
                        setDevices(response); // Set devices in local state
                    } else {
                        setError('Failed to fetch devices');
                    }
                } catch (err) {
                    console.error('Failed to fetch devices:', err);
                    setError('Failed to fetch devices'); // Set error if fetch fails
                } finally {
                    setIsLoading(false); // Set loading to false when done
                }
            } else if (selectedTab !== 'devices') {
                setIsLoading(false);
            }
        };

        getDevices(); // Call the function to fetch devices when the selected tab is "devices"
    }, [subAdminId, selectedTab]); // Re-run effect when subAdminId or selectedTab changes

    // Handle logout by clearing localStorage and redirecting to login
    const handleLogout = () => {
        localStorage.removeItem('subadminToken');
        localStorage.removeItem('subadminId');
        localStorage.removeItem('subadminEmail');
        localStorage.removeItem('subadminDevices');
        navigate('/subadmin/login'); // Redirect to login page
    };

    // Rendering based on selected tab
    const renderContent = () => {
        if (selectedTab === 'devices') {
            return (
                <div className="device-grid">
                    <h3>Your Devices</h3>
                    {isLoading && <p>Loading devices...</p>} {/* Show loading message */}
                    {error && <p>Error: {error}</p>} {/* Show error message */}
                    {devices.length > 0 ? (
                        devices.map((device, index) => (
                            <div key={index} className="device-card">
                                <strong>MAC ID:</strong> {device.macId} <br />
                                <strong>Model No:</strong> {device.deviceModelno} <br />
                                <strong>Available Capabilities:</strong> {JSON.stringify(device.availableCapabilities)} <br />
                                <strong>Selected Capabilities:</strong> {JSON.stringify(device.selectedCapabilities)}
                            </div>
                        ))
                    ) : (
                        !isLoading && <p>No devices found.</p> // Avoid showing "No devices found" while loading
                    )}
                </div>
            );
        } else if (selectedTab === 'manageOps') {
            return <div><h3>Manage Ops</h3><p>Ops management feature goes here.</p></div>;
        } else if (selectedTab === 'planValidity') {
            return <div><h3>Plan Validity</h3><p>Plan validity feature goes here.</p></div>;
        }
    };

    return (
        <div className="dashboard-container">
            <div className="sidebar">
                <h3>Menu</h3>
                <ul>
                    <li onClick={() => setSelectedTab('devices')}>Devices</li>
                    <li onClick={() => setSelectedTab('manageOps')}>Manage Ops</li>
                    <li onClick={() => setSelectedTab('planValidity')}>Plan Validity</li>
                </ul>
                <button onClick={handleLogout} className="logout-button">Logout</button> {/* Logout button */}
            </div>
            <div className="main-content">
                {renderContent()} {/* Render the content based on the selected tab */}
            </div>
        </div>
    );
};

export default SubAdminDashboard;
