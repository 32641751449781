import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route, RouterProvider, createBrowserRouter, createRoutesFromElements
} from "react-router-dom";
import { selectIsAuthChecked, selectLoggedInUser } from './features/auth/AuthSlice';
import { Logout } from './features/auth/components/Logout';
import { Protected } from './features/auth/components/Protected';
import { useAuthCheck } from "./hooks/useAuth/useAuthCheck";
import { useFetchLoggedInUserDetails } from "./hooks/useAuth/useFetchLoggedInUserDetails";
import { AddProductPage, AdminOrdersPage, CartPage, CheckoutPage, ForgotPasswordPage, HomePage, LoginPage, OrderSuccessPage, OtpVerificationPage, ProductDetailsPage, ProductUpdatePage, ResetPasswordPage, SignupPage, UserOrdersPage, UserProfilePage, WishlistPage } from './pages';
import { AdminDashboardPage } from './pages/AdminDashboardPage';
import { NotFoundPage } from './pages/NotFoundPage';
import SubAdminDashboardPage  from './pages/SubAdminDashboardPage';
import { AddOpsTeamPage } from './pages/AddOpsTeamPage';
import PrivacyPolicy from './features/footer/PrivacyPolicy';
import RefundPolicy from './features/footer/RefundPolicy';
import AboutUs from './features/footer/AboutUs';
import ShippingPolicy from './features/footer/ShippingPolicy';
import SubAdminLoginPage  from './pages/SubAdminLoginPage'; 
import PaymentPage from './pages/PaymentPage';

import OrderSuccess from './pages/OrderSuccess';


function App() {

  const isAuthChecked = useSelector(selectIsAuthChecked);
  const loggedInUser = useSelector(selectLoggedInUser);

  useAuthCheck();
  useFetchLoggedInUserDetails(loggedInUser);

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* Public Routes */}
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/verify-otp' element={<OtpVerificationPage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/reset-password/:userId/:passwordResetToken' element={<ResetPasswordPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/shipping-policy' element={<ShippingPolicy />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/' element={<HomePage />} /> {/* HomePage accessible without login */}

        {/* Protected Routes */}
        <Route path='/logout' element={<Protected><Logout /></Protected>} />
        <Route path='/product-details/:id' element={<Protected><ProductDetailsPage /></Protected>} />
        <Route path='/cart' element={<Protected><CartPage /></Protected>} />
        <Route path='/profile' element={<Protected><UserProfilePage /></Protected>} />
        <Route path='/checkout' element={<Protected><CheckoutPage /></Protected>} />
        <Route path='/order-success/:id' element={<Protected><OrderSuccessPage /></Protected>} />
        <Route path='/orders' element={<Protected><UserOrdersPage /></Protected>} />
        <Route path='/wishlist' element={<Protected><WishlistPage /></Protected>} />
        <Route path='/order-success' element={<Protected><OrderSuccess /></Protected>} />

        

        {/* Admin Routes */}
        {loggedInUser?.isAdmin && (
          <>
            <Route path='/admin/dashboard' element={<Protected><AdminDashboardPage /></Protected>} />
            <Route path='/admin/product-update/:id' element={<Protected><ProductUpdatePage /></Protected>} />
            <Route path='/admin/add-product' element={<Protected><AddProductPage /></Protected>} />
            <Route path='/admin/orders' element={<Protected><AdminOrdersPage /></Protected>} />
            <Route path='*' element={<Navigate to={'/admin/dashboard'} />} />
          </>
        )}

        {/* Sub-admin Routes - No condition, open for everyone */}
        <Route path='/subadmin/login' element={<SubAdminLoginPage />} /> 
        <Route path='/subadmin/dashboard' element={<Protected><SubAdminDashboardPage /></Protected>} />
        <Route path='/subadmin/add-ops-team' element={<Protected><AddOpsTeamPage /></Protected>} />
        <Route path='*' element={<Navigate to={'/subadmin/dashboard'} />} />

        {/* Catch-all for Non-admin, Non-subadmin Routes */}
        {!(loggedInUser?.isAdmin) && (
          <Route path='*' element={<NotFoundPage />} />
        )}
    
        <Route path='/payment_page' element={<PaymentPage/>}/>
      </>
    )
  );

  return isAuthChecked ? <RouterProvider router={routes} /> : null;
}

export default App;