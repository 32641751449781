import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Box>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                {/* About Us */}
                            {/* Privacy Policy */}
                <Typography variant="h6" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We are committed to protecting your privacy. This privacy policy explains how we collect, use, and protect your personal information when you use our website or services.
                    We collect personal information such as your name, email, address, and payment details to process your orders. Your information is securely stored and will not be shared with third parties without your consent, except as necessary to fulfill your orders or comply with legal obligations.
                    You have the right to access, update, or delete your personal information by contacting us at privacy@yourcompany.com. For more details, please review our complete privacy policy on our website.
                </Typography>
              
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
